// React
import React from 'react'

// css
import './css/Header.css'

// Bibliotecas
import { Link } from 'react-router-dom';

// Componets

// Fotos
import userIcon from './../../assets/user.png'


const Assider = () => {
    return (
        <>

            {/* <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button> */}

            <div id="sidebar" className=" bg-white h-screen md:block shadow-xl px-3 w-30 md:w-60 lg:w-60 overflow-x-hidden transition-transform duration-300 ease-in-out" x-show="sidenav">
                <div className="pb-4 space-y-6 md:space-y-10 mt-10">
                    <h1 className="font-bold text-4xl text-center ">
                        Marca
                    </h1>
                    <div id="profile" className="space-y-3">
                        <img
                            src={userIcon}
                            alt="Avatar user"
                            class="w-10 md:w-16 rounded-full mx-auto"
                        />
                        <div className='text-center'>
                            <h2 className="font-bold text-xs md:text-base text-center text-teal-500">
                                Admin
                            </h2>
                            <span className='font-semibold text-xs md:text-sm text-center text-teal-500'>Admin@gmail.com</span>
                        </div>
                    </div>
                </div>

                <div id="menu" className="flex flex-col space-y-2 border-t-2" >



                    <Link to="/" className="flex items-center text-sm font-medium text-gray-700 py-2 px-2 hover:bg-teal-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                        <div className="w-7 fill-current inline-block text-xl">
                            <ion-icon name="home-outline"></ion-icon>
                        </div>
                        <span className="">Home</span>
                    </Link>

                    <Link to="/" className="flex items-center text-sm font-medium text-gray-700 py-2 px-2 hover:bg-teal-500 hover:text-white hover:scale-105 rounded-md transition duration-150 ease-in-out">
                        <div className="w-7 fill-current inline-block text-xl">
                            <ion-icon name="cart-outline"></ion-icon>
                        </div>
                        <span>Pedidos</span>
                    </Link>

                    <Link to="/produto" className="flex items-center text-sm font-medium text-gray-700 py-2 px-2 hover:bg-teal-500 hover:text-white hover:scale-105 rounded-md transition duration-150 ease-in-out">
                        <div className="w-7 fill-current inline-block text-xl">
                            <ion-icon name="pricetags-outline"></ion-icon>
                        </div>
                        <span>Produtos</span>
                    </Link>

                    <Link to="/" className="flex items-center text-sm font-medium text-gray-700 py-2 px-2 hover:bg-teal-500 hover:text-white hover:scale-105 rounded-md transition duration-150 ease-in-out">
                        <div className="w-7 fill-current inline-block text-xl">
                            <ion-icon name="person-outline"></ion-icon>
                        </div>
                        <span>Users</span>
                    </Link>
                </div>
            </div>


           
        </>
    )
}

export default Assider