// Biblioteca
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";

// Css
import "./css/formulario.css";
import { LoadProdutos } from "../utils/loadProdutos";
import DropZone from "../DropZone/upload";

// Validação do Formulario
const validationPost = yup.object().shape({
  unidade: yup.string().typeError("Por favor preencha o campo unidade").min(0),
  descricao: yup
    .string()
    .required("Por favor preencha o campo descrição")
    .max(100, "O campo descrição precisar ter menos de 100 caracteres"),
  estoque: yup
    .number()
    .typeError("Por favor preencha o campo estoque")
    .min(0, "Por favor insira valor maior que 0"),
  referencia: yup
    .string()
    .max(100, "O campo descrição precisar ter menos de 100 caracteres"),
  valor: yup
    .number()
    .required()
    .typeError("Por favor preencha o campo valor")
    .min(0, "Por favor insira valor maior que 0"),
  marca: yup.string(),
  ativo: yup.bool(),
});

export const Formulario = ({ openModal, setOpenModal }) => {
  const [isChecked, setIsChecked] = useState(false);

  // console.log("Meu CheckBox", isChecked);

  const { id } = useParams();
  // console.log(`Esse e o meu id:${id}`);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");

  // let history = useHistory()
  const navigate = useNavigate();

  const { refresh } = LoadProdutos();

  //const editPost = (data) => console.log(data);
  const editPost = (data) =>
    axios
      .patch(`https://api.catalogo.website/v1/api/products/${id}`, {
        description: data.descricao,
        urlImage: selectedFileUrl,
        unid: data.unidade,
        ref: data.referencia,
        brand: data.marca,
        value: data.valor,
        stock: data.estoque,
        isActive: data.ativo,
      })
      .then(() => {
        console.log("Foi enviado com sucesso os dados!");
        // window.location.reload();
        setOpenModal(false)
        refresh()
      })
      .catch((error) => {
        console.log("Erro no envio", error);
      });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  useEffect(() => {
    axios
      .get(`https://api.catalogo.website/v1/api/products/${id}`)
      .then((resp) => {
        reset({
          unidade: resp.data.unid,
          descricao: resp.data.description,
          estoque: resp.data.stock,
          referencia: resp.data.ref,
          valor: resp.data.value,
          imagem: resp.data.urlImage,
          marca: resp.data.brand,
          ativo: resp.data.isActive,
        });
      })
      .catch((err) => {
        console.log("Deu errado a requisição", err);
      });
  }, [id]);

  if (openModal) {
    return (
      <div className="Background_modal fadein">
        <div className="Conteudo_modal top-4 left-1 right-5 md:inset-0 z-50 justify-center items-center">
          <div className="formulario">
            <form onSubmit={handleSubmit(editPost)}>
              <div className="form_title">
                <div className="titulo">
                  <h1>Formulário de Edição</h1>
                  <button
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <ion-icon name="close-outline"></ion-icon>
                  </button>
                </div>
                <div className="form_envio"></div>
              </div>

              <div className="container_form">
                <div className="container_label">
                  <div className="container_input">
                    <label
                      htmlFor="website-admin"
                      className="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Unidade:
                    </label>
                    <div className="icon_descricao">
                      <ion-icon name="key"></ion-icon>
                    </div>
                    <input
                      {...register("unidade")}
                      type="text"
                      name="unidade"
                      id="unidade"
                      disabled
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="John"
                    />
                    <p className="erro_menssage">{errors.unidade?.message}</p>
                  </div>

                  <div className="container_input">
                    <label
                      htmlFor="Descrição"
                      className="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Descrição:
                    </label>
                    <div className="icon_descricao">
                      <ion-icon name="clipboard"></ion-icon>
                    </div>
                    <input
                      {...register("descricao")}
                      type="text"
                      name="descricao"
                      id="descricao"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <p className="erro_menssage">{errors.descricao?.message}</p>
                  </div>

                  <div className="container_input">
                    <label
                      htmlFor="referencia"
                      className="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Referência:
                    </label>

                    <input
                      {...register("referencia")}
                      type="text"
                      name="referencia"
                      id="referencia"
                      className="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      disabled
                    />
                    <p className="erro_menssage">
                      {errors.referencia?.message}
                    </p>
                  </div>

                  <div>
                    <label
                      htmlFor="estoque"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Estoque:
                    </label>
                    <div className="icon_descricao">
                      <ion-icon name="storefront"></ion-icon>
                    </div>
                    <input
                      {...register("estoque")}
                      type="text"
                      name="estoque"
                      id="estoque"
                      className='cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Flowbite" '
                      disabled
                    />
                    <p className="erro_menssage">{errors.estoque?.message}</p>
                  </div>

                  <div>
                    <label
                      htmlFor="valor"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Valor:
                    </label>
                    <div className="icon_descricao">
                      <ion-icon name="calculator"></ion-icon>
                    </div>
                    <input
                      {...register("valor")}
                      type="text"
                      name="valor"
                      id="valor"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <p className="erro_menssage">{errors.valor?.message}</p>
                  </div>

                  <div>
                    <DropZone upload={setSelectedFileUrl} />
                    {/* <input
                      name="file"
                      className="block text-sm text-gray-90 rounded-lg cursor-pointer"
                      type="file"
                      id="file"
                      accept="image/*"
                    /> */}
                    <input type="f" />
                  </div>

                  <div>
                    <label
                      htmlFor="marca"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      marca
                    </label>

                    <input
                      type="text"
                      className="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="MARCA"
                      disabled
                    />
                    <p className="erro_menssage">{errors.marca?.message}</p>
                  </div>

                  <div>
                    <label
                      htmlFor="ativo"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Ativo
                    </label>

                    <input
                      {...register("ativo")}
                      //checked={isChecked}
                      //onChange={() => setIsChecked(!isChecked)}
                      name="ativo"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                </div>
              </div>

              <div className="container_button">
                <button
                  type="submit"
                  className="button_envia text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Envia
                </button>
                <button
                  type="button"
                  className="button_cancela text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Limpa
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};
