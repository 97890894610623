import React, { useState } from "react";
import InfiniteScroll from "../InfiniteScroll/InfiniteScroll";
import { Link } from "react-router-dom";
import { formatBoolean, formatCurrency } from "../utils/utils";

const PostProdutos = ({ produto, setOpenModal }) => {
  return (
    <tr
      key={produto.id}
      className={
        !produto.isActive
          ? "text-red-400 bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          : "text-gray-900 bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      }
    >
      <td className="w-32 p-4">
        <img src={produto.urlImage} alt="Nenhuma Imagem" />
      </td>
      <td className="px-6 py-4 font-semibold dark:text-white">
        {produto.description}
      </td>
      <td className="px-6 py-4">
        <div className="flex items-center space-x-3">
          <div className="inline-flex items-center justify-center p-1 text-sm font-medium h-6 w-6 ">
            {produto.unid}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 font-semibold dark:text-white">{produto.ref}</td>
      <td className="px-6 py-4 font-semibold dark:text-white">
        {produto.brand}
      </td>
      <td className="px-6 py-4">{formatCurrency(produto.value)}</td>
      <td className="px-4 py-2 lg:py-4 lg:px-6 font-semibold dark:text-white">
        {produto.stock}
      </td>
      <td className="px-4 py-2 lg:py-4 lg:px-6 font-semibold dark:text-white">
        {formatBoolean(produto.isActive)}
      </td>
      <td className="button_acao px-6 py-4">
        {/* <button
                onClick={() => { deletePost(produto.id) }} className="button_remove bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm lg:text-base px-5 py-2.5 lg:px-6 lg:py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Remove</button> */}
        <Link to={{ pathname: `/produto/edit/${produto._id}` }}>
          <button
            onClick={() => {
              setOpenModal();
            }}
            className="bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm lg:text-base px-5 py-2.5 lg:px-6 lg:py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Editar
          </button>
        </Link>
      </td>

      {/* QUANDO TIVER O NOSSO DADOS NA TELA FAZ SENTID SER MONITORADO */}
      {/* {loading && (
        <InfiniteScroll fetchMore={() => console.log('Apareceu na tela')} />
      )} */}
    </tr>
  );
};

export default PostProdutos;
