import React, { useCallback, useState } from "react";

// BiBLioteca
import { useDropzone } from "react-dropzone";
import axios from "axios";

const DropZone = ({ upload }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [selectedFileName, setSelectedFileName] = useState(
    "Nenhum arquivo escolhido"
  );

  // Arquivo para ser recebido
  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Do something with the files
      const file = acceptedFiles[0];
      console.log(acceptedFiles);

      // const fileUrl = URL.createObjectURL(file)
      // setSelectedFileUrl(fileUrl)
      upload(file);
      setSelectedFileName(file.name)

      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("file", file);

      await axios
        .post(
          "https://api.catalogo.website/v1/api/products/upload",
          formData,
          headers
        )
        .then((res) => {
          console.log("Deu certo a requisição", res.data);
          setSelectedFileUrl(res.data.Location);
          upload(res.data.Location);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [upload]
  );

  console.log("Minha imagem", selectedFileUrl);

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <div {...getRootProps()}>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        Upload file
      </label>

      <input
        ref={inputRef}
        name="file"
        className="block text-sm text-gray-90 rounded-lg cursor-pointer"
        type="file"
        id="file"
        // {...getInputProps()}
        accept="image/*"
        title="teste"
      />
      <p
        className="mt-1 text-sm text-gray-500 dark:text-gray-300"
        id="user_avatar_help"
      >
        {selectedFileName}
      </p>
    </div>
  );
};
export default DropZone;
