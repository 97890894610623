import React from 'react'

import Assider from '../../components/Menu/Assider';

const Home = () => {
  return (
    <div>
      <Assider/>
      
    </div>
    
  )
}

export default Home