import { useEffect, useRef } from "react";

const InfiniteScroll = ({ fetchMore, page, pageTotal }) => {
  const containerRef = useRef();

  useEffect(() => {
    const options = {
      root: document.querySelector(".conteudo"),
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        observer.disconnect();
        fetchMore();
      }
    }, options);

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [fetchMore, page]); // Observa apenas a mudança em fetchMore
  return <div ref={containerRef} />;
};

export default InfiniteScroll;
