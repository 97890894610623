// React
import React, { useEffect, useState } from "react";

// CSS
import "./css/FormHeader.css";
import { useRecoilState } from "recoil";
import { filtrosAtom } from "./../../atoms/filtrosAtom";
import { LoadProdutos } from "../utils/loadProdutos";

// Biblioteca

const FormHeader = ({ setOpenModal, setBrands, pesquisa, setPesquisa }) => {
  const [filtro, setFiltro] = useRecoilState(filtrosAtom);
  const [selectedBrand, setSelectedBrand] = useState("");

  // Função de filtro
  const handleBrandClick = (brand) => {
    setBrands(brand);
    setSelectedBrand(brand);
  };

  useEffect(() => {
    const brandFromStorage = localStorage.getItem("selectedBrand");
    if (brandFromStorage) {
      setSelectedBrand(brandFromStorage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedBrand", selectedBrand);
  }, [selectedBrand]);

  // console.log("url da API de produtos: ", filtro + `&page=1&limit=20`);

  return (
    <>
      <section>
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="">
            <div className="flex items-center gap-x-3">
              <h2 className="text-3xl font-medium text-gray-800 dark:text-white">
                Produtos
              </h2>
            </div>
          </div>

          <div className="flex items-center mt-4 gap-x-3">
            <button className="text-slate-50 flex items-center justify-center w-1/2 px-5 py-2 text-sm bg-green-400 transition-colors duration-200 border rounded-lg gap-x-2 sm:w-auto focus:ring-green-300 hover:bg-green-500 focus:ring-4 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <span>Adicionar Produto</span>
            </button>
          </div>
        </div>

        <div className="mt-6 md:flex md:items-center md:justify-between">
          {/* Marcas */}
          <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700">
            <button
              onClick={() => {
                setFiltro("todos");
                handleBrandClick("");
              }}
              className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm dark:bg-gray-800 dark:text-gray-300"
            >
              Todos
            </button>

            <button
              className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
              onClick={() => {
                setFiltro("marcas");
                handleBrandClick("peining");
              }}
            >
              Peining
            </button>

            <button
              onClick={() => {
                setFiltro("marcas");
                handleBrandClick("devia");
              }}
              className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
            >
              Devia
            </button>

            <button
              onClick={() => {
                setFiltro("marcas");
                handleBrandClick("kimaster");
              }}
              className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
            >
              Kimaster
            </button>
            <button
              onClick={() => {
                setFiltro("marcas");
                handleBrandClick("hrebos");
              }}
              className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
            >
              Hrebos
            </button>
            <button
              onClick={() => {
                setFiltro("marcas");
                handleBrandClick("b-max");
              }}
              className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100"
            >
              B-Max
            </button>
            {/* <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm dark:hover:bg-gray-800 dark:text-gray-300 hover:bg-gray-100">
              Atualizar
            </button> */}
          </div>
          {/* Input search */}
          <div className="relative flex items-center mt-4 md:mt-0">
            <span className="absolute">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </span>

            <input
              type="serch"
              placeholder="Search"
              className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
              value={pesquisa}
              onChange={(e) => setPesquisa(e.target.value)}
            />
          </div>
        </div>
      </section>
    </>

    // <>
    //     <div className='container_header'>
    //         <h2 className='title'>Produtos</h2>
    //         <div className='container_pesquisa relative'>
    //             <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
    //                 <ion-icon name="search-outline"></ion-icon>
    //             </div>
    //             <input type="search" id="default-search" className='block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder="Search Mockups, Logos..." required />
    //             <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Limpa Formulário</button>
    //             <button onClick={() => setOpenModal(false)} className='btn_excluir text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Adicionar Produto</button>
    //         </div>
    //     </div>
    //     <div>
    //     </div>
    // </>
  );
};

export default FormHeader;
