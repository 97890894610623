// React
import { useEffect, useState } from "react";

// BiBlioteca
import axios from "axios";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { filtrosAtom } from "../../atoms/filtrosAtom";

export const LoadProdutos = (page) => {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageTotal, setPageTotal] = useState(0);
  const [limitPage, setLimitPage] = useState();
  const [filtro, setFiltro] = useRecoilState(filtrosAtom);
  const [limpo, setLimpo] = useState(true);

  useEffect(() => {
    if (filtro === "todos") {
      axios
        .get(`https://api.catalogo.website/v1/api/products?limit=${limitPage}`)
        .then((resp) => {
          setLoading(true);
          setProdutos(resp.data.content);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Erro na requisição", err);
          setLoading(false);
        });
    } else if (filtro === "marcas") {
      axios
        .get(
          `https://api.catalogo.website/v1/api/products?page=${page}&limit=10`
        )
        .then((resp) => {
          console.log("Deu certo a requisição", pageTotal);
          // Função para limitar o total da pagina
          setPageTotal(Math.ceil(resp.data.total / 10));
          // setLimitPage(resp.data.total);
          setProdutos((prevProdutos) => [
            ...prevProdutos,
            ...resp.data.content,
          ]);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Deu certo a requisição", err);
          setLoading(false);
        });
    } else {
      axios
        .get(
          `https://api.catalogo.website/v1/api/products?page=${page}&limit=10`
        )
        .then((resp) => {
          console.log("Deu certo a requisição", pageTotal);
          // Função para limitar o total da pagina
          setPageTotal(Math.ceil(resp.data.total / 10));
          setLimitPage(resp.data.total);
          setProdutos([...produtos, ...resp.data.content]);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Deu certo a requisição", err);
          setLoading(false);
        });
    }
  }, [page, filtro /*pageTotal, produtos*/]);

  return { loading, produtos, pageTotal };
};
