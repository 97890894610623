// Bibliotecas
import React, { useEffect, useMemo, useState } from "react";

// Componetes
import { LoadProdutos } from "./../utils/loadProdutos";
import PostProdutos from "../ListProdutos/ListProdutos";

// Styles
import "./css/FormContent.css";
import axios from "axios";
import InfiniteScroll from "../InfiniteScroll/InfiniteScroll";
import FormHeader from "../FormHeader/FormHeader";
import { filtrosAtom } from "./../../atoms/filtrosAtom";
import { useRecoilValue } from "recoil";

const FormContent = ({ setOpenModal, brands, pesquisa }) => {
  const [page, setPage] = useState(1);
  const filtros = useRecoilValue(filtrosAtom);

  const { loading, produtos, pageTotal } = LoadProdutos(page, setPage);

  const selectedBrandFromStorage = localStorage.getItem("selectedBrand");

  const fetchMore = () => {
    if (page < pageTotal && !loading) {
      setPage(page + 1);
    }
  };

  function HandleScroll() {
    setPage(page + 1);
    // setPageTotal(pageTotal + 10)
  }

  const deletePost = async (productId) => {
    try {
      await axios.delete(
        `https://api.catalogo.website/v1/api/products/${productId}`
      );
      console.log("Produto removido com sucesso!");
    } catch (error) {
      console.error("Erro ao remover o produto", error);
    }
  };

  const filtroBrands = useMemo(() => {
    if (pesquisa === "") {
      // Se o campo de pesquisa estiver vazio, filtre apenas pela marca
      return produtos.filter((produtoFiltro) =>
        produtoFiltro.brand.toUpperCase().includes(selectedBrandFromStorage.toUpperCase())
      );
    } else {
      // Se houver algo digitado no campo de pesquisa, filtre com base na pesquisa
      return produtos.filter((produtoFiltro) =>
        produtoFiltro.description.toLowerCase().includes(pesquisa.toLowerCase())
      );
    }
  }, [produtos, brands, pesquisa]);

  // console.log('Valor de brands:', brands);
  //console.log('Valor de pesquisa:', pesquisa);
  // console.log('Produtos filtrados:', filtroBrands);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("https://api.catalogo.website/v1/api/products");
  //       setProdutos(response.data.content);
  //       setLoading(false); // Marque o carregamento como concluído
  //     } catch (error) {
  //       console.error("Erro ao buscar produtos", error);
  //       setLoading(false); // Marque o carregamento como concluído, mesmo em caso de erro
  //     }
  //   };

  //   fetchData();
  // }, []);

  // console.log(produtos)

  return (
    <>
      {/* <div className='seccao'> 
        <FormHeader />
      </div> */}

      <div className="mt-3 relative overflow-x-auto shadow-md sm:rounded-lg border">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Imagem
              </th>
              <th scope="col" className="px-6 py-3">
                Descrição
              </th>
              <th scope="col" className="px-6 py-3">
                Unidade
              </th>
              <th scope="col" className="px-6 py-3">
                Referencia
              </th>
              <th scope="col" className="px-6 py-3">
                Marca
              </th>
              <th scope="col" className="px-5 py-3">
                Valor R$
              </th>
              <th scope="col" className="px-5 py-3">
                Estoque
              </th>
              <th scope="col" className="px-5 py-3">
                Ativo?
              </th>
              <th scope="col" className="px-20 py-3">
                Acões
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr colSpan="8">
                <p>Carregando...</p>
              </tr>
            ) : (
              filtroBrands.map((produto) => (
                <PostProdutos
                  key={produto.id}
                  produto={produto}
                  setOpenModal={setOpenModal}
                />
              ))
            )}
          </tbody>
          {!loading && page >= pageTotal && !filtros ? (
            <p>Não tem mais produtos!</p>
          ) : (
            <InfiniteScroll
              page={page}
              fetchMore={fetchMore}
              pageTotal={pageTotal}
            />
          )}
        </table>
      </div>
    </>
    // <div className='tabela_produto relative overflow-x-auto shadow-md sm:rounded-lg'>
    //   <table className='tabela_container w-full text-sm text-left text-gray-500 dark:text-gray-400'>
    //     <thead className='tabela_cabecalho text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
    //       <tr>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           <span className="sr-only">
    //             Image
    //           </span>
    //         </th>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           Descrição
    //         </th>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           Unidade
    //         </th>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           Referencia
    //         </th>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           Marca
    //         </th>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           stock
    //         </th>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           Valor
    //         </th>
    //         <th className="px-4 py-2 lg:py-3 lg:px-6">
    //           Ação
    //         </th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {loading ? (
    //         <p>Carregando...</p>
    //       ) : (
    //         produtos.map((produto) => (
    //           <PostProdutos key={produto.id} produto={produto} setOpenModal={setOpenModal}/>
    //         ))
    //       )}
    //     </tbody>
    //     {!loading && <InfiniteScroll page={page} fetchMore={fetchMore} />}
    //   </table>
    // </div>
  );
};

export default FormContent;
